import { Box, Button, TextField, Typography } from "@mui/material";

import { ActionFunctionArgs, redirect } from "react-router";
import { Link } from "react-router-dom";
import { useActionData, useLoaderData } from "react-router-typesafe";

import { getSessionState, verifyToken } from "~/api";
import { FormContainer } from "~/components/FormContainer";
import { localize } from "~/localize";

export async function loader() {
	let state = await getSessionState();

	if (!state.hasIdentity) {
		return redirect("/");
	} else if (state.isAuthenticated && !state.hasPersonalia) {
		return redirect("/registration");
	} else if (state.isAuthenticated && state.hasPersonalia) {
		return redirect("/courses");
	}

	return { state };
}

export async function action({ request }: ActionFunctionArgs) {
	let formData = await request.formData();
	let token = formData.get("token");

	if (!token || typeof token !== "string") {
		return localize("E-postadressen er ikke en gyldig e-post.", "E-mail address is not a valid e-mail.");
	}

	let state = await verifyToken(token);

	if (state.error) {
		return state.error;
	}
	console.log(state);
	return redirect(state.hasPersonalia ? "/courses" : "/registration");
}

export function Component() {
	let { state } = useLoaderData<typeof loader>();
	let error = useActionData<typeof action>();

	return (
		<FormContainer language={state.language} error={error}>
			<Box textAlign="center">
				<Typography variant="h4" component="p">
					{localize("Verifiseringskode", "Verification token")}
				</Typography>
				<Typography>
					{localize("Vi har sendt en kode til", "We have sent a token to")} <strong>{state.email}</strong>
				</Typography>
			</Box>
			<TextField name="token" label={localize("Kode", "Token")} fullWidth />
			<Box display="flex" gap={2} justifyContent="center">
				<Button component={Link} to="/logout" size="large" variant="outlined" type="submit">
					{localize("Tilbake", "Back")}
				</Button>
				<Button size="large" variant="contained" type="submit">
					{localize("Bekreft kode", "Confirm code")}
				</Button>
			</Box>
		</FormContainer>
	);
}
